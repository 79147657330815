html {
  font-size: 15px !important;
}

body {
  background-color: #efefef;
  /* background-image: url(); */
  max-width: 1400px;
  margin: 0 auto;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  height: 100%;
}

header {
  background-color: #efefef;
  box-shadow: 0px 0px 4px #000000;
}

footer {
  background-color: #393b40;
  bottom: 0;
  display: flex !important;
  text-align: left;
  align-items: center;
  vertical-align: bottom;
  justify-content: center;
  color: #eaeaea;
  text-shadow: 1px 1px 1px #000;
  font-family: "Dosis", sans-serif;
  font-size: 12px;
  padding: 5px 10px;
  max-width: 1400px;
  height: 60px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  line-height: 1.4em;
  z-index: 999999;
}

.body {
  background-color: #efefef;
  padding: 5px;
  margin-bottom: 0px;
  box-shadow: 0px 1px 4px #000;
  z-index: 1;
  border-radius: 5px 5px 0px 0px;
  margin-top: -1px;
}

.margin-fix {
  margin: 0px !important;
}

.margin-padding-fix {
  margin: 0px !important;
  padding-bottom: 0px !important;
}

.margin-bottom-fix {
  margin-bottom: 0px !important;
}

.padding-fix {
  padding: 0px !important;
}

.ui.label,
.ui.labels .label {
  font-size: 14px !important;
}

.ui.menu {
  font-size: 16px !important;
}

i.icon,
i.icons {
}

.header-break {
  height: 10px;
  background-color: #393b40;
  box-shadow: inset 0px 0px 3px #000;
  border-top: 1px solid #0c0c0c;
  border-bottom: 1px solid #ffffff;
}

.header-top-line {
  font-size: 14px;
  padding: 10px 10px;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-shadow: 1px 1px 1px #fff;
}

.color-red-regular {
  color: #ff0000;
}
.color-red {
  color: #ff0000;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.5em;
}
.color-orange {
  color: #fd4e41;
}
.heading-size {
  font-size: 36px;
  line-height: 2em;
}

.icons {
  width: 20px;
  height: 20px;
}
.height300px {
  height: 300px;
}
.height-100 {
  height: 100%;
}
.height-fix {
  height: 100px;
}
.height-icon-fix {
  height: inherit !important;
}

.center-fix {
  justify-content: center;
  width: 100%;
  text-align: center;
}

.center-fix-check {
  justify-content: center;
  width: 100%;
  text-align: center;
  display: flex !important;
  flex-wrap: wrap;
}

.field-margin-fix {
  margin: 1.5em 0 !important;
}

.item-fix {
  padding: 0.8em 0 !important;
}

.content-fix {
  font-size: 17px;
  display: inline;
}

.content-upload-fix {
  display: flex;
  min-height: 32px;
  align-items: center;
  overflow: hidden;
}

.content-list-fix {
  padding: 4px 0px 0px 0px;
}

.ui.avatar.image > .disabled {
  background-color: #ccc;
}

.disabled-fix {
  cursor: default;
  opacity: 0.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

.custom-lable {
  font-size: 16px;
  font-family: "arial";
  /* margin-top: 4px; */
  margin-right: 8px;
  width: 30px;
  padding: 0px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #393b40;
  color: #fff;
}

.custom-lable.disabled {
  background-color: #b6b8bd;
}

.with-icon {
  text-align: right;
}

.ui.avatar.image {
  border-radius: 0em !important;
}

.h3-custom {
  color: #fff;
  text-align: left;
  text-shadow: 1px 1px 2px #000;
}

.with-color {
  background-color: #393b40;
  border-bottom: 1px solid #000000;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4196078431372549);
  background-image: url("./images/brilliant.png");
}

.no-border-bot {
  border-bottom: 0px !important;
}
.column.fix-column-header .ui.menu {
  box-shadow: none;
  border: none;
}
.fix-column-header {
  padding: 0px !important;
  background-color: #b23636;
}

.logo-fix-header {
  display: flex;
  width: 100%;
  padding: 10px 5px;
}

.header-menu-section {
  border-bottom: 3px solid #efefef;
  border-left: 5px solid #efefef;
  border-right: 5px solid #efefef;
  background-color: #dcdcdc;
  background-image: url("./images/bg.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 320px;
  box-shadow: inset 0px 1px 3px 0px rgb(4, 4, 4);
}

.footer-container {
  display: flex;
  align-items: flex-start;
}
.footer-left-fill {
  width: 25%;
}
.footer-center-fill {
  width: 75%;
  text-align: right;
}
.footer-center-fill a {
  color: #b7b7b7;
}
.footer-center-fill a:hover {
  color: #b7b7b7;
}

.ui.grid {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.ui.segment {
  -webkit-box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.31) !important;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.31) !important;
  border: none !important;
}

.ui.message {
  font-size: 15px !important;
}

select.ui.dropdown {
  height: 40.34px !important;
}

.code-flex {
  display: flex;
  align-items: center;
}

.marg-left {
  margin-left: 2px !important;
}

.error-fix {
  text-align: center;
  width: 445px;
}

.ui.form .field > label {
  font-size: 15px !important;
}

.ui.form .grouped.fields > label {
  font-size: 15px !important;
}

.display {
  display: block !important;
}

/* --Greg's CSS */
.ui.borderless.stackable.menu {
  background: #efefef;
}
.ui.menu.no-border-bot {
  border: none;
  background: #efefef;
}
.ui.menu.no-border-bot a {
  color: black;
}
.ui.menu.no-border-bot a:hover {
  color: lightgrey;
}

.ui.segment.login {
  width: 50%;
  margin: 0 auto;
}
.login-header {
  color: white;
}
